import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { Fragment } from "react"
import { Trans, useTranslation } from "react-i18next"
import Header from "../components/header"
import MarkdownHTML from "../components/MarkdownHTML"
import PageFooter from "../components/PageFooter/PageFooter"
import PageHeader from "../components/PageHeader/PageHeader"
import Seo from "../components/seo"

const AcademyPage = ({ data }) => {
  const { t } = useTranslation()
  const { language } = useI18next()

  return (
    <div>
      <Seo 
        title={t(data.context.pageContext.section)}
        description={t(`${data.context.pageContext.section}_subtext`)}
        lang={language}
      />

      <PageHeader
        title={t(data.context.pageContext.section)}
        section={data.context.pageContext.section}
      />

      <main>
        <MarkdownHTML data={data} name="academy_general" />

        <StaticImage
          src="../images/track.jpg"
          loading="eager"
          width={600}
          quality={60}
          formats={["auto", "webp", "avif"]}
          alt="Our track"
        />

        <MarkdownHTML data={data} name="safety_kit" />

        <MarkdownHTML data={data} name="motorbikes" />
      </main>

      <PageFooter />
    </div>
  )
}

export default AcademyPage

export const query = graphql`
  query AcademyPage($language: String!, $path: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    context: sitePage(path: { eq: $path }) {
      pageContext
    }

    allMarkdownRemark(filter: { frontmatter: { lang: { eq: $language } } }) {
      nodes {
        id
        frontmatter {
          name
          title
          lang
        }
        html
      }
    }
  }
`
